import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import ErrorMessage from "../parts/ErrorMessage";
import { signIn, resendSignUpCode, signInWithRedirect } from "aws-amplify/auth";
import { appConstants } from "../../../utils/appConstants";

export default function Login() {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [isShow, setIsShow] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>(appConstants.FAILURE_LOGIN);
  const navigate = useNavigate();

  const togglePasswordVisibility = () => {
    setIsShow(!isShow);
  };

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();

    try {
      const result = await signIn({ username, password });

      // console.log(result);

      switch (result.nextStep.signInStep) {
        case "DONE":
          navigate("/");
          break;
        case "CONFIRM_SIGN_UP":
          const sendResult = await resendSignUpCode({ username });
          if (sendResult) {
            navigate("/signup-input-code", { state: { username, password } });
          }
          break;
        default:
          console.log("default case transit");
          break;
      }
    } catch (loginError) {
      if (typeof loginError === "object" && loginError !== null) {
        if ("name" in loginError) {
          const loginErrorName = (loginError as { name: string }).name;
          switch (loginErrorName) {
            case "NotAuthorizedException":
              setErrorMessage(appConstants.FAILURE_LOGIN);
              break;
            case "EmptySignInUsername":
              setErrorMessage(appConstants.EMPTY_USERNAME);
              break;
            case "EmptySignInPassword":
              setErrorMessage(appConstants.EMPTY_PASSWORD);
              break;
            default:
              console.log(loginErrorName);
              setErrorMessage(appConstants.OTHER_ERROR);
          }
          setError(true);
        }
      }
    }
  };

  return (
    <>
      <div className="commTitle icoMember">ログイン or 会員登録</div>
      <div className="loginWrap">
        <div className="formWrap">
          <ErrorMessage error={error} errorMessage={errorMessage} />
          <form onSubmit={handleSubmit}>
            <div className="inputWrap">
              <input 
                type="text"
                name="user"
                id="user"
                value={username}
                placeholder=""
                onChange={(e) => setUsername(e.target.value)}
              />
              <div className="placeholder">
                メールアドレス
              </div>
            </div>
            <div className="inputWrap">
              <span
                id="eye"
                className={isShow ? "fa fa-eye" : "fa fa-eye-slash"}
                onClick={togglePasswordVisibility}
              />
              <input
                type={isShow ? "text" : "password"}
                name="pass"
                id="pass"
                placeholder=""
                maxLength={20}
                onChange={(e) => setPassword(e.target.value)}
              />
              <div className="placeholder">
                パスワード
              </div>
            </div>
            <div className="submitWrap">
              <Link to="/reset-pw">パスワード再発行</Link>
              <input type="submit" value="ログイン" />
            </div>
          </form>
          <div className="selectionWrap">
            <div className="loginBorder">または</div>
            <div className="buttonWrap">
              {/* <button className="button line" onClick={() => signInWithRedirect({ provider: { custom: "LINE" } })}> */}
              <button className="button line" onClick={() => alert("LINEでログインを行います（モックのため未実装）")}>
                <div className="image">
                  <img src="/images/socialloginicon-line.png" alt="LINE" />
                </div>
                <div className="text">
                  LINEでログイン
                </div>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="loginOr">
        or
      </div>
      <div className="loginWrap">
        <div className="signin">
          <Link to="/signup">
            新規会員登録
            <span>60秒！簡単登録！</span>
          </Link>
        </div>
      </div>
    </>
  );
}
