export class appConstants {
  // SUCCESS
  static readonly SUCCESS_UPDATE: string = "更新が完了しました";
  static readonly SEND_CODE: string = "認証コードを送信しました";
  static readonly SIGNUP_SUCCESS: string = "【会員登録完了】ログイン中です。";

  // INFO
  static readonly AGREE_INFO: string = "会員規約、プライバシーポリシーに同意をお願いします";

  // ERROR
  static readonly FAILURE_LOGIN: string = "メールアドレスまたはパスワードを確認してください";
  static readonly CONFIRM_PASSWORD_MISMATCH: string = "パスワードが一致しません";
  static readonly INVALID_CODE: string = "認証コードを確認してください";
  static readonly INVAIDE_MAIL: string = "メールアドレスの形式に誤りがあるか、入力されたメールアドレスが未登録です";
  static readonly INVALID_PASSWORD: string = "パスワードは8桁以上、大文字/小文字/数字/記号をそれぞれ1つ以上使用してください";
  static readonly EXISTS_EMAIL: string = "このメールアドレスはすでに登録されています";
  static readonly EXPIRED_CODE: string = "認証コードの有効期限が切れています";
  static readonly ADDRESS_NOT_FOUND: string = "ご指定された郵便番号の住所は見つかりませんでした";
  static readonly SESSION_DISCONNECTION: string = "セッションが切断されました。再度ログインしてお試しください";
  static readonly EMPTY_USERNAME: string = "メールアドレスを入力してください";
  static readonly EMPTY_PASSWORD: string = "パスワードを入力してください";
  static readonly OTHER_ERROR: string = "ご登録内容をご確認の上、再度お試しください";
}
