import React, { useState, useEffect } from 'react';
// import Modal from 'react-modal';

// Modal.setAppElement('#root');

interface TextModalProps {
    isOpen: boolean;
    onRequestClose: () => void;
    contentLabel: string;
    url: string;
}

const TextModal: React.FC<TextModalProps> = ({ isOpen, onRequestClose, contentLabel, url }) => {
  const [text, setText] = useState<string>("");

  useEffect(() => {
    fetch(url)
      .then(response => response.text())
      .then(data => setText(data))
      .catch(error => console.error("Error loading the text file: ", error));
  }, [url]);

  return (
    <></>
    // <Modal
    //   isOpen={isOpen}
    //   onRequestClose={onRequestClose}
    //   contentLabel={contentLabel}
    //   className="modal"
    //   overlayClassName="overlay"
    // >
    //   <textarea readOnly value={text} style={{ width: '100%', height: '300px' }} />
    //   <div className='closeWrap'>
    //     <button onClick={onRequestClose}>閉じる</button>
    //   </div>
    // </Modal>
  );
}

export default TextModal;
