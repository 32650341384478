import { Link } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { getCurrentUser } from 'aws-amplify/auth';
import { useSelector } from 'react-redux';
import { RootState } from '../../stores/store';
import CouponComponent from '../parts/coupon/CouponComponent';


export default function Coupon() {
  const today = new Date().toISOString().slice(0, 10); // YYYY-MM-DD 形式の今日の日付
  const [login, setLogin] = useState(false);
  const couponData = useSelector((state: RootState) => state.coupons.coupons);

  useEffect(() => {
    checkAuthState();
  }, []);

  const checkAuthState = async () => {
    try {
      await getCurrentUser();
      setLogin(true);
    } catch {
      setLogin(false);
    }
  };

  function showModal() {
    alert('ログインが必要です。');
  }

  return (

    <div className="couponWrap commSpacer single">
      <div className="commTitle icoCoupon">クーポン 一覧</div>
      <div className="coupons">
        {couponData.filter(coupon => coupon.endtime > today).map(coupon => (
          <div className="items" key={coupon.id}>
            {coupon.special === "1" && !login ? (
              <div onClick={showModal}>
                <CouponComponent coupon={coupon} />
              </div>
            ) : (
              <Link to={`/coupon/${coupon.id}`}>
                <CouponComponent coupon={coupon} />
              </Link>
            )}
          </div>
        ))}
      </div>
    </div>
  );
}
