// app/store.ts
import { configureStore } from '@reduxjs/toolkit';
import couponReducer from '../stores/slices/couponSlice';
import infoReducer from '../stores/slices/infoSlice';
import topicReducer from '../stores/slices/topicSlice';

const store = configureStore({
  reducer: {
    coupons: couponReducer,
    infos: infoReducer,
    topics: topicReducer,
  },
});

export default store;
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;