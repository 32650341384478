import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from '../../stores/store';
import axios from 'axios';
// import { infoData } from '../../mocup/mocData';

const InfoDetail: React.FC = () => {
  const [infoImage, setInfoImage] = useState<string>("");

  const infoData = useSelector((state: RootState) => state.infos.infos);
  
  // URLからnewsIdパラメータを取得
  const { id } = useParams<{ id: string }>();

  // newsIdに一致するニュースを検索
  const info = infoData.find((n: { id: { toString: () => string | undefined; }; }) => n.id.toString() === id);

  // 画像パスを動的生成
  const imageUrl = `/images/news-${id}.png`;

  useEffect(() => {
    getImageInfos();
  }, []);

  const getImageInfos = async () => {
    try {
      const imageFileName = `info-1-1-${id}`;
      const imageRes = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/api/image/${imageFileName}`,
        {
          headers: {
            'Content-Type': 'application/json'
          },
          responseType: 'blob',
        }
      );
      const imageUrl = URL.createObjectURL(imageRes.data);
      setInfoImage(imageUrl);
    } catch {
      console.log('error');
    }
  };


  return (
    <div>
      {info ? (
        <div className="newsWrap commSpacer single">
          <div className="commTitle icoNews">{info.title}</div>
          <div className="commTextbody">
            <img src={infoImage} alt="info" /><br /><br />
            <div dangerouslySetInnerHTML={{ __html: info.body }} />
          </div>
        </div>
      ) : (
        <p>ニュースが見つかりません。</p>
      )}
    </div>
  );
};
export default InfoDetail;
