import React, { useEffect, useState } from 'react';
import MicroModal from 'micromodal';
import '../../../styles/micromodal.css';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/pagination';
import { useSelector } from 'react-redux';
import { RootState } from '../../../stores/store';
import axios from 'axios';

interface FirstPopupModalProps {
  isActive: boolean;
  onClose: () => void;
}

const FirstPopupModal: React.FC<FirstPopupModalProps> = ({ isActive, onClose }) => {
  const topicData = useSelector((state: RootState) => state.topics.topics);

  // 最新3件を取得
  const recentTopics = topicData.slice(0, 3);

  useEffect(() => {
    MicroModal.init({
      disableScroll: true,
      awaitOpenAnimation: true,
      awaitCloseAnimation: true,
    });

    if (isActive) {
      MicroModal.show('modalTop', {
        onShow: modal => console.log('Modal is shown'),
        onClose: onClose
      });
    }
  }, [isActive, onClose]);


  const TopicComponent = ({ topic }: { topic: any }) => {
    const [topicImage, settopicImage] = useState<string>("");
  
    useEffect(() => {
      getImagetopics();
    }, []);
  
    const getImagetopics = async () => {
      try {
        const imageFileName = `topic-1-1-${topic.id}`;
        const imageRes = await axios.get(
          `${process.env.REACT_APP_API_BASE_URL}/api/image/${imageFileName}`,
          {
            headers: {
              'Content-Type': 'application/json'
            },
            responseType: 'blob',
          }
        );
        const imageUrl = URL.createObjectURL(imageRes.data);
        settopicImage(imageUrl);
      } catch {
        console.log('error');
      }
    };
  
    return (
      <>
        <a href={topic.link}>
          <img alt="topic" src={topicImage} />
        </a>
        <div>{topic.title}</div>
      </>
    );
  }

  return (
    <div id="modalTop" aria-hidden="true" className="modal micromodal-slide">
      <div className="modal__overlay" tabIndex={-1} data-micromodal-close>
        <div className="modal__container" role="dialog" aria-modal="true" aria-labelledby="modal-title">
          <Swiper loop={true} pagination={true} modules={[Pagination]} className='topicsWrap'>
            {recentTopics.map(topic => (
              <SwiperSlide style={{width: "1170px"}} key={topic.id} >
                <TopicComponent topic={topic} />
              </SwiperSlide>
            ))}
          </Swiper>
          <button className="modal__close" aria-label="Close modal" data-micromodal-close onClick={onClose}></button>
        </div>
      </div>
    </div>
  );
};
export default FirstPopupModal;
