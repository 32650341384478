import { Outlet } from "react-router-dom";
import "../../styles/LoginLayout.css";

export default function LoginLayout() {
  return (
    <>
      <header>
        <title>Smares</title>
        <link
          href="https://use.fontawesome.com/releases/v5.6.1/css/all.css"
          rel="stylesheet"
        ></link>
      </header>
      <main>
        <div className="loginCont commSpacer single">
          <Outlet />
        </div>
      </main>
    </>
  );
}
