import { useState } from "react";
import { Link } from "react-router-dom";
import "../../../styles/hamburger-menu.css";
import Drawer from "@mui/material/Drawer";

const HamburgerMenu = () => {
  const [drawerOpened, setDrawerOpened] = useState(false);

  return (
    <>
      <button
        className="hamburger"
        type="button"
        id="js-buttonHamburger"
        aria-expanded="false"
        onClick={() => setDrawerOpened(true)}
      >
        <span className="hamburger-line">
          <span className="visuallyHidden" />
        </span>
      </button>

      <Drawer
        anchor={"right"}
        open={drawerOpened}
        onClose={() => setDrawerOpened(false)}
        PaperProps={{ style: { width: "60%" } }}
      >
        <div className="mainMenu" aria-hidden="true">
          <ul>
            <li>
              <img src={"/images/logo.svg"} alt="logo" style={{ width: "140px" }} />
            </li>
            <li>
              <Link
                to="/"
                className="nav-link"
                onClick={() => setDrawerOpened(false)}
                style={{ textDecoration: "none" }}
              >
                <img src={"/images/icon/icoHome.svg"} alt="home" />
                ホーム
              </Link>
            </li>
            <li>
              <Link
                to="/coupon"
                className="nav-link"
                onClick={() => setDrawerOpened(false)}
                style={{ textDecoration: "none" }}
              >
                <img src={"/images/icon/icoCoupon.svg"} alt="coupon" />
                クーポン
              </Link>
            </li>
            <li>
              <Link
                to="/info"
                className="nav-link"
                onClick={() => setDrawerOpened(false)}
                style={{ textDecoration: "none" }}
              >
                <img src={"/images/icon/icoInfo.svg"} alt="info" />
                お知らせ
              </Link>
            </li>
            <li>
              <Link
                to="/offer"
                className="nav-link"
                onClick={() => setDrawerOpened(false)}
                style={{ textDecoration: "none" }}
              >
                <img src={"/images/icon/icoMypage.svg"} alt="mypage" />
                スペシャル
              </Link>
            </li>
          </ul>
        </div>
      </Drawer>
    </>
  );
};
export default HamburgerMenu;
