import axios from "axios";
import { useEffect, useState } from "react";

const CouponComponent = ({ coupon }: { coupon: any }) => {
  const [couponImage, setCouponImage] = useState<string>("");

  useEffect(() => {
    getImageCoupons();
  }, []);

  const getImageCoupons = async () => {
    try {
      const imageFileName = `coupon-1-1-${coupon.id}`;
      const imageRes = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/api/image/${imageFileName}`,
        {
          headers: {
            'Content-Type': 'application/json'
          },
          responseType: 'blob',
        }
      );
      const imageUrl = URL.createObjectURL(imageRes.data);
      setCouponImage(imageUrl);
    } catch {
      console.log('error');
    }
  };

  return (
    <div className="coupon">
      {coupon.special === "1" ? (
        <>
          <div className="memberOnly">
            Only<br/>Special
          </div>
          <div className="limit">
            使用回数<span>0</span>/1
          </div>
        </>
      ) : null}
      <div className="top">
        <div className="image">
          <img src={couponImage} alt="coupon-item" />
        </div>
        <div className="name">{coupon.name}</div>
      </div>
      <div className="bottom">
        <div className="price">
          <div className="before">
            {coupon.teika}<span>円</span>
          </div>
          <div className="after">
            {coupon.baika}<span>円</span>
          </div>
        </div>
        <div className="date">有効期限 {coupon.kigen}</div>
      </div>
    </div>
  );
}
export default CouponComponent;