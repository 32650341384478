import React, { createContext, useContext, useState, ReactNode } from "react";
import { Severity } from "../types";
import CustomSnackbar from "./CustomSnackbar";

type SnackbarContextType = {
  open: boolean;
  severity: Severity;
  message: string;
  showSnackbar: (severity: Severity, message: string) => void;
  handleClose: () => void;
};

const SnackbarContext = createContext<SnackbarContextType | undefined>(
  undefined
);

export const useSnackbarContext = () => {
  const context = useContext(SnackbarContext);
  if (!context) {
    throw new Error("useSnackbarはAuthProvider内で使用してください");
  }
  return context;
};

export const SnackbarProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [open, setOpen] = useState(false);
  const [severity, setSeverity] = useState<Severity>("info");
  const [message, setMessage] = useState("");

  const showSnackbar = (severity: Severity, message: string) => {
    setOpen(true);
    setSeverity(severity);
    setMessage(message);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <SnackbarContext.Provider
      value={{ open, severity, message, showSnackbar, handleClose }}
    >
      <CustomSnackbar
        open={open}
        handleClose={handleClose}
        severity={severity}
        message={message}
      />
      {children}
    </SnackbarContext.Provider>
  );
};
