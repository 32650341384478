import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import ErrorMessage from "../parts/ErrorMessage";
import { appConstants } from "../../../utils/appConstants";
import { resetPassword } from "@aws-amplify/auth";

export default function ForgotPassword() {
  const [username, setUsername] = useState("");
  const [error, setError] = useState(false);
  const navigate = useNavigate();
  const errorMessage = appConstants.INVAIDE_MAIL;

  const onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    try {
      // usernameにはメールアドレスを設定
      const result = await resetPassword({ username });
      if (result) {
        navigate("/reset-pw-input-code", { state: { username } });
      }
    } catch {
      setError(true);
    }
  };

  return (
    <>
      <div className="commTitle icoMember">パスワードの再設定</div>
      <div className="loginWrap">
        <div className="formWrap">
          <ErrorMessage error={error} errorMessage={errorMessage} />
          <form onSubmit={onSubmit}>
            <div className="inputWrap">
              <input
                type="text"
                name="user"
                id="user"
                onChange={(e) => setUsername(e.target.value)}
                placeholder=""
                required
              />
              <div className="placeholder">メールアドレス</div>
            </div>
            <div className="submitWrap">
              <input type="submit" value="認証コードを送信する" />
            </div>
          </form>
        </div>
      </div>
    </>
  );
}
