import React, { useEffect } from 'react';
import MicroModal from 'micromodal';
import '../../../styles/micromodal.css';

interface CouponQRModalProps {
  isOpen: boolean;
  onClose: () => void;
  code: string;
}

const CouponQRModal: React.FC<CouponQRModalProps> = ({ isOpen, onClose, code }) => {

  // TODO QRコードを取得する（DBのをそのまま？）
  // const fetchQRCode = async () => {};

  useEffect(() => {
    MicroModal.init({
      disableScroll: true,
      awaitOpenAnimation: true,
      awaitCloseAnimation: true,
    });

    if (isOpen) {
      MicroModal.show('CouponQRModal', {
        onShow: modal => console.log('QR Modal is shown'),
        onClose: onClose
      });
    }
  }, [isOpen, onClose]);

  return (
    <div id="CouponQRModal" aria-hidden="true" className="modal micromodal-slide">
      <div className="modal__overlay" tabIndex={-1} data-micromodal-close="">
        <div className="modal__container single" role="dialog" aria-modal="true" aria-labelledby="modal-title">
          <div className="couponcodeWrap">
            <div className="msg1">
              クーポン
            </div>
            <div className="code">
              <img src="/images/dummyQr.png" alt="coupon-qrcode" />
            </div>
            <div className="msg2">
              店舗スタッフにご提示ください
            </div>
          </div>
          <button className="modal__close" aria-label="Close modal" data-micromodal-close=""></button>
        </div>
      </div>
    </div>
  );
};
export default CouponQRModal;
