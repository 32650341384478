import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

export interface Topic {
  id: number;
  title: string;
  body: string;
  link: string;
  created_at: string;
  updated_at: string;
  status: number;
  delflg: number;
}

interface TopicState {
  topics: Topic[];
  loading: boolean;
  error: string | null;
}

const initialState: TopicState = {
  topics: [],
  loading: false,
  error: null
};

export const fetchTopics = createAsyncThunk('topics/fetchTopics', async () => {
  const response = await axios.get<Topic[]>(`${process.env.REACT_APP_API_BASE_URL}/api/topic`);
  return response.data;
});

const topicSlice = createSlice({
  name: 'topics',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchTopics.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchTopics.fulfilled, (state, action) => {
        state.loading = false;
        state.topics = action.payload;
      })
      .addCase(fetchTopics.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || 'Something went wrong';
      });
  }
});

export default topicSlice.reducer;
