import "./App.css";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import {
  Route,
  BrowserRouter,
  Routes
} from "react-router-dom";
import { Amplify } from "aws-amplify";

import PublicRoute from "./routes/PublicRoute";
import LoginLayout from "./auth/components/templates/LoginLayout";
import DefaultLayout from "./components/templates/DefaultLayout";
import Login from "./auth/components/pages/Login";
import SignUp from "./auth/components/pages/SignUp";
import SignUpInputCode from "./auth/components/pages/SignUpInputCode";
import ResetPw from "./auth/components/pages/ResetPw";
import ResetPwInputCode from "./auth/components/pages/ResetPwInputCode";
import Home from "./components/pages/Home";
import Coupon from "./components/pages/Coupon";
import Info from "./components/pages/Info";
import Offer from "./components/pages/Offer";
import InfoDetail from "./components/pages/InfoDetail";
import CouponDetail from "./components/pages/CouponDetail";
import { fetchCoupons } from "./stores/slices/couponSlice";
import { fetchInfos } from "./stores/slices/infoSlice";
import { fetchTopics } from "./stores/slices/topicSlice";
import { AppDispatch } from "./stores/store";
import NotFoundPage from "./components/pages/NotFoundPage";

function App() {
  Amplify.configure({
    Auth: {
      Cognito: {
        userPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID as string,
        userPoolClientId: process.env.REACT_APP_COGNITO_CLIENT_ID as string,
        loginWith: {
          oauth: {
            domain: 'outh-sample-2.auth.ap-northeast-1.amazoncognito.com',
            scopes: ['profile','openid','email'],
            responseType: 'code',
            redirectSignIn: [
              'http://localhost:3000',
              'https://auth-sample.smares.jp'
            ],
            redirectSignOut: [
              'http://localhost:3000',
              'https://auth-sample.smares.jp'
            ],
          }
        },
      },
    },
  });

  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    dispatch(fetchCoupons());
    dispatch(fetchInfos());
    dispatch(fetchTopics());
  }, [dispatch]);
  
  return (
    <BrowserRouter>
      <Routes>
        <Route element={<DefaultLayout />}>
          <Route path="/" element={<Home />} />
          <Route path="/coupon" element={<Coupon />} />
          <Route path="/coupon/:id" element={<CouponDetail />} />
          <Route path="/info" element={<Info />} />
          <Route path="/info/:id" element={<InfoDetail />} />
          <Route path="/offer" element={<Offer />} />

          <Route element={<PublicRoute><LoginLayout /></PublicRoute>}>
            <Route path="/login" element={<Login />} />
            <Route path="/signup" element={<SignUp />} />
            <Route path="/signup-input-code" element={<SignUpInputCode />} />
            <Route path="/reset-pw" element={<ResetPw />} />
            <Route path="/reset-pw-input-code" element={<ResetPwInputCode />} />
          </Route>
        </Route>

        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
