import { useEffect, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/pagination';
import FirstPopupModal from '../parts/modal/FirstPopupModal';
import { Link } from 'react-router-dom';
import { getCurrentUser } from 'aws-amplify/auth';
import CouponComponent from '../parts/coupon/CouponComponent';
import { useSelector } from 'react-redux';
import { RootState } from '../../stores/store';


export default function Home() {
  const today = new Date().toISOString().slice(0, 10); // YYYY-MM-DD形式
  const couponData = useSelector((state: RootState) => state.coupons.coupons);
  const infoData = useSelector((state: RootState) => state.infos.infos);
  const [login, setLogin] = useState(false);
  const [isModalActive, setModalActive] = useState(false);

  useEffect(() => {
    checkAuthState();
    const cookieValue = getCookie('topicksModalShow');
    if (!cookieValue) {
      setModalActive(true);
    }
  }, []);

  const checkAuthState = async () => {
    try {
      await getCurrentUser();
      setLogin(true);
    } catch {
      setLogin(false);
    }
  };

  const handleCloseModal = () => {
    // 7日間の有効期間でCookieを設定
    setCookie('topicksModalShow', '1', 7);
    setModalActive(false);
  };

  // Cookieを取得する
  const getCookie = (name: string) => {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop()?.split(';').shift();
    return null;
  };

  // Cookieを設定する
  const setCookie = (name: string, value: string, days: number) => {
    const date = new Date();
    date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
    const expires = `expires=${date.toUTCString()}`;
    document.cookie = `${name}=${value}; ${expires}; path=/`;
  };

  // Cookieを削除する
  const deleteAllCookies = () => {
    const cookies = document.cookie.split(";");
    for (let cookie of cookies) {
      const eqPos = cookie.indexOf("=");
      const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
      document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/`;
    }
  };

  const showModal = () => {
    alert('ログインが必要です。');
  }

  // 最新3件のニュースを取得
  const recentInfo = infoData.slice(0, 4);

  // 最新4件のクーポンを取得
  const recentCoupons = couponData
    .filter(coupon => coupon.endtime > today)
    .sort((a, b) => b.endtime.localeCompare(a.endtime)) // 有効期限で降順ソート
    .slice(0, 4);

  // 最新3件の追加要素(広告)を取得
  // const recentAd = adData.slice(0, 3);

  return (
  <>
    {/* <div style={{ padding: '2em 1em' }}>
      店舗A　｜　店舗B　｜　店舗C
    </div> */}

    {/* <Swiper loop={true} pagination={true} modules={[Pagination]} className='topicsWrap'>
      {recentInfo.map(info => (
        <SwiperSlide style={{width: "1170px"}} key={`swiper-${info.id}`} >
          <Link to={`/info/${info.id}`}>
            <img alt="news" src={`/images/news-${info.id}.png`} />
          </Link>
        </SwiperSlide>
      ))}
    </Swiper> */}

    <br/>

    <div className="newsWrap commSpacer">
      <div className="commTitle icoNews">お知らせ</div>
      {recentInfo.map(info => (
        <div className="items shortTitle" key={`item-${info.id}`} >
          <Link to={`/info/${info.id}`}>{info.title}</Link>
        </div>
      ))}
      <div className="commMore">
        <Link to={'/info'}>もっと見る</Link>
      </div>
    </div>

    <div className="couponWrap commSpacer">
      <div className="commTitle icoCoupon">新着クーポン</div>
      <div className="coupons">
        {recentCoupons.filter(coupon => coupon.endtime > today).map(coupon => (
          <div className="items" key={`item-${coupon.id}`}>
            {coupon.special === "1"  && !login ? (
              <div onClick={showModal}>
                <CouponComponent coupon={coupon} />
              </div>
            ) : (
              <Link to={`/coupon/${coupon.id}`}>
                <CouponComponent coupon={coupon} />
              </Link>
            )}
          </div>
        ))}
      </div>
      <div className="commMore">
        <Link to={'/coupon'}>もっと見る</Link>
      </div>
    </div>

    {/* <div className="adWrap commSpacer">
      {recentAd.map(ad => (
        <div className="items" key={`item-${ad.id}`}>
          <a href={ad.url}>
            <img src={`/images/ad-${ad.id}.png`} alt="ad" />
          </a>
        </div>
      ))}
    </div> */}

    <div>
      {/* <div className="modalTopReview" data-micromodal-trigger="modalTop" role="button" onClick={() => setModalActive(true)}>
        お得な情報をもう一度みる（初回ポップアップ）
      </div>
      <div className="modalTopDeactiveCookie" onClick={deleteAllCookies}>
        Cookie削除
      </div> */}
      {isModalActive && <FirstPopupModal isActive={isModalActive} onClose={handleCloseModal} />}
    </div>

  </>
  );
}
