import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import CouponCodeModal from '../parts/modal/CouponCodeModal';
import CouponQRModal from '../parts/modal/CouponQRModal';
import { getCurrentUser } from 'aws-amplify/auth';
import { useSelector } from 'react-redux';
import { RootState } from '../../stores/store';
import axios from 'axios';

const CouponDetail: React.FC = () => {
  const [couponImage, setCouponImage] = useState<string>("");
  const [codeModalIsOpen, setCodeModalIsOpen] = useState(false);
  const [qrModalIsOpen, setQrModalIsOpen] = useState(false);
  const couponData = useSelector((state: RootState) => state.coupons.coupons);
  
  
  // URLからnewsIdパラメータを取得
  const { id } = useParams<{ id: string }>();

  // newsIdに一致するニュースを検索
  const coupon = couponData.find((n: { id: { toString: () => string | undefined; }; }) => n.id.toString() === id);

  // モーダルを開く
  const openModal = () => {
    if ( coupon?.code.includes('QR') ) {
      setQrModalIsOpen(true)
    } else {
      setCodeModalIsOpen(true)
    }
    return;
  };

  const [login, setLogin] = useState(false);

  useEffect(() => {
    checkAuthState();
    getImageCoupons();
  }, []);

  const checkAuthState = async () => {
    try {
      await getCurrentUser();
      setLogin(true);
    } catch {
      setLogin(false);
    }
  };


  const getImageCoupons = async () => {
    try {
      const imageFileName = `coupon-1-1-${id}`;
      const imageRes = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/api/image/${imageFileName}`,
        {
          headers: {
            'Content-Type': 'application/json'
          },
          responseType: 'blob',
        }
      );
      const imageUrl = URL.createObjectURL(imageRes.data);
      setCouponImage(imageUrl);
    } catch {
      console.log('error');
    }
  };


  return (
    <>
      {coupon ? (
        <div className="couponWrap commSpacer single">
          <div className="commTitle icoCoupon">{coupon.name}</div>
          <div className="coupons single">
            <div className="items">
              <div className="coupon">
                {coupon.special === "1" ? (
                  <>
                    <div className="memberOnly">
                      Only<br/>Special
                    </div>
                    <div className="limit">
                      使用回数<span>0</span>/1
                    </div>
                  </>
                ) : null}
                <div className="top">
                  <div className="image">
                    <img src={couponImage} alt="coupon-item" />
                  </div>
                  <div className="name">{coupon.name}</div>
                </div>
                <div className="bottom">
                  <div className="price">
                    <div className="before">
                      {coupon.teika}<span>円</span>
                    </div>
                    <div className="after">
                      {coupon.baika}<span>円</span>
                    </div>
                  </div>
                  <div className="date">有効期限 {coupon.endtime}</div>
                </div>
              </div>
            </div>
            <div className="couponInfo" onClick={openModal} role="button">
              クーポンを表示する
            </div>
          </div>
          <div className="couponNotice">
            * 有効期限が切れたクーポンはご利用いただけません。<br/> * 使用済みクーポンは、誤操作によるものであっても、使用可能な状態にすることはできません。<br/> * クーポンのご利用にあたり、スタッフが不正と判断した場合、クーポンを利用することができません。あらかじめご了承ください。
            <br/>
          </div>
          <CouponCodeModal 
            isOpen={codeModalIsOpen}
            onClose={() => setCodeModalIsOpen(false)}
            isSpecial={coupon.special}
            code={coupon.code}
            login={login}
          />
          <CouponQRModal 
            isOpen={qrModalIsOpen}
            onClose={() => setQrModalIsOpen(false)}
            code={coupon.code} 
          />
        </div>
      ) : (
        <p>クーポンが見つかりません。</p>
      )}
    </>
  );
};
export default CouponDetail;
