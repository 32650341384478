import React from "react";
import "../../styles/LoginLayout.css";

interface Props {
  error: boolean;
  errorMessage: string;
}

const ErrorMessage: React.FC<Props> = ({ error, errorMessage }) => {
  if (!error) {
    return null;
  }

  return (
    <div className="errWrap">
      <div>
        <span>!</span>
        {errorMessage}
      </div>
    </div>
  );
};

export default ErrorMessage;
