import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { RootState } from '../../stores/store';
// import { infoData } from '../../mocup/mocData';

export default function Info() {
  const infoData = useSelector((state: RootState) => state.infos.infos);

  return (
    <>
      <div className="newsWrap commSpacer single">
        <div className="commTitle icoNews">お知らせ 一覧</div>
        {infoData.map(info => (
          <div className="items shortTitle" key={info.id} >
            <Link to={`/info/${info.id}`}>{info.title}</Link>
          </div>
        ))}
      </div>
    </>
  );
}
