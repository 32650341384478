import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSnackbarContext } from "../../../utils/snackbar/SnackbarContext";
import { appConstants } from "../../../utils/appConstants";
import ErrorMessage from "../parts/ErrorMessage";
import TextModal from "../parts/modal/TextModal";
import { signUp } from "aws-amplify/auth";

export default function Signup() {
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isShow, setIsShow] = useState(false);
  const [isShow2, setIsShow2] = useState(false);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isTermsOpen, setTermsOpen] = useState(false);
  const [isPrivacyOpen, setPrivacyOpen] = useState(false);
  const navigate = useNavigate();
  const { showSnackbar } = useSnackbarContext();

  const [isMailAgreed, setIsMailAgreed] = useState(true);
  const [isAgreed, setIsAgreed] = useState(false);

  const mailAgreeCheckboxChange = (event: any) => {
    setIsMailAgreed(event.target.checked);
  }

  const agreeCheckboxChange = (event: any) => {
    setIsAgreed(event.target.checked);
  };

  const togglePasswordVisibility = () => {
    setIsShow(!isShow);
  };

  const togglePasswordVisibility2 = () => {
    setIsShow2(!isShow2);
  };

  const onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (!isAgreed) {
      showSnackbar("info", appConstants.AGREE_INFO);
      return;
    }

    if (password !== confirmPassword) {
      setErrorMessage(appConstants.CONFIRM_PASSWORD_MISMATCH);
      setError(true);
      return;
    }

    try {
      const username = email;
      const { nextStep } = await signUp({
        username,
        password,
        options: { userAttributes: { email } },
      });

      // メールアドレス確認を実施しない場合に使用
      // if (isSignUpComplete) {
      //   navigate("/login");
      // }

      if (nextStep.signUpStep === "CONFIRM_SIGN_UP") {
        navigate("/signup-input-code", { state: { username, password } });
      }
    } catch (signUpError) {
      if (typeof signUpError === "object" && signUpError !== null) {
        if ("name" in signUpError) {
          const signUpErrorName = (signUpError as { name: string }).name;
          switch (signUpErrorName) {
            case "InvalidParameterException":
              setErrorMessage(appConstants.INVAIDE_MAIL);
              break;
            case "InvalidPasswordException":
              setErrorMessage(appConstants.INVALID_PASSWORD);
              break;
            case "UsernameExistsException":
              setErrorMessage(appConstants.EXISTS_EMAIL);
              break;
            default:
              setErrorMessage(appConstants.OTHER_ERROR);
          }
          setError(true);
          return;
        }
      }
    }
  };

  return (
    <>
      <div className="commTitle icoMember">新規会員登録</div>
      <div className="loginWrap">
        <div className="formWrap">
          <ErrorMessage error={error} errorMessage={errorMessage} />
          <form onSubmit={onSubmit}>
            <div className="inputWrap">
              <input
                type="text"
                name="user"
                id="user"
                onChange={(event) => setEmail(event.target.value)}
                placeholder=""
                required
              />
              <div className="placeholder">メールアドレス（ユーザーID）</div>
            </div>
            <div className="inputWrap">
              <span
                id="eye"
                className={isShow ? "fa fa-eye" : "fa fa-eye-slash"}
                onClick={togglePasswordVisibility}
              ></span>
              <input
                type={isShow ? "text" : "password"}
                name="pass"
                id="pass"
                onChange={(event) => setPassword(event.target.value)}
                placeholder=""
                maxLength={20}
                required
              />
              <div className="placeholder">パスワード</div>
            </div>
            <div className="inputWrap">
              <span
                id="eye"
                className={isShow2 ? "fa fa-eye" : "fa fa-eye-slash"}
                onClick={togglePasswordVisibility2}
              ></span>
              <input
                type={isShow2 ? "text" : "password"}
                name="pass2"
                id="pass2"
                onChange={(event) => setConfirmPassword(event.target.value)}
                placeholder=""
                maxLength={20}
                required
              />
              <div className="placeholder">パスワード（再入力）</div>
            </div>
            <div className="optinWrap">
              <div className="docs">
                <a onClick={() => alert("会員規約を表示")}>会員規約</a><br/>
                <a onClick={() => alert("プライバシーポリシーを表示")}>プライバシーポリシー</a><br/>
              </div>
              <div className="button">
                <input
                  type="checkbox"
                  name="mailAgree"
                  id="mailAgree"
                  checked={isMailAgreed}
                  onChange={mailAgreeCheckboxChange}
                />
                <label htmlFor="mailAgree">
                  メールでお得なクーポン情報を受け取る
                </label>
              </div>
              <div className="button">
                <input
                  type="checkbox"
                  name="agree"
                  id="agree"
                  checked={isAgreed}
                  onChange={agreeCheckboxChange}
                />
                <label htmlFor="agree">
                  会員規約、プライバシーポリシーに同意する
                </label>
              </div>
            </div>
            <div className="submitWrap">
              <input type="submit" value="認証コードを送信する" />
            </div>
          </form>
        </div>
      </div>
      <TextModal
        isOpen={isTermsOpen}
        onRequestClose={() => setTermsOpen(false)}
        contentLabel="会員規約"
        url="/membership-terms.txt"
      />
      <TextModal
        isOpen={isPrivacyOpen}
        onRequestClose={() => setPrivacyOpen(false)}
        contentLabel="プライバシーポリシー"
        url="/privacy-policy.txt"
      />
    </>
  );
}
