import React from 'react';


const NotFoundPage: React.FC = () => {
  return (
    <>
      <header>
        <title>Smares</title>
        <link
          href="https://use.fontawesome.com/releases/v5.6.1/css/all.css"
          rel="stylesheet"
        ></link>
      </header>
      <main>
        <div>
          <h1>404 Not Found</h1>
          <p>お探しのページは見つかりませんでした。</p>
        </div>
      </main>
    </>
  );
};
export default NotFoundPage;
