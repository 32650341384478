import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

export interface Coupon {
  id: number;
  name: string;
  teika: string;
  baika: string;
  code: string;
  usagelimit: string;
  special: string;
  starttime: string;
  endtime: string;
  created_at: string;
  updated_at: string;
  status: number;
  delflg: number;
}

interface CouponState {
  coupons: Coupon[];
  loading: boolean;
  error: string | null;
}

const initialState: CouponState = {
  coupons: [],
  loading: false,
  error: null
};

export const fetchCoupons = createAsyncThunk('coupons/fetchCoupons', async () => {
  // 認証チャネルの場合
  // const { idToken } = (await fetchAuthSession()).tokens ?? {};
  // const response = await axios.get<Coupon[]>('http://localhost:8070/api/coupon', {
  //   headers: {
  //     'Authorization': `Bearer ${idToken}`
  //   }
  // });
  // return response.data;
  const response = await axios.get<Coupon[]>(`${process.env.REACT_APP_API_BASE_URL}/api/coupon`);
  return response.data;
});

const couponSlice = createSlice({
  name: 'coupons',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchCoupons.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchCoupons.fulfilled, (state, action) => {
        state.loading = false;
        state.coupons = action.payload;
      })
      .addCase(fetchCoupons.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || 'Something went wrong';
      });
  }
});

export default couponSlice.reducer;
