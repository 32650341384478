import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import ErrorMessage from "../parts/ErrorMessage";
import { appConstants } from "../../../utils/appConstants";
import { useSnackbarContext } from "../../../utils/snackbar/SnackbarContext";
import { confirmResetPassword, resetPassword } from "@aws-amplify/auth";

export default function ResetPwInputCode() {
  const [confirmationCode, setConfirmationCode] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isResending, setIsResending] = useState<boolean>(false);
  const [timer, setTimer] = useState<number>(0);
  const [isShow, setIsShow] = useState<boolean>(false);
  const [isShow2, setIsShow2] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>(
    appConstants.INVALID_CODE
  );
  const navigate = useNavigate();
  const location = useLocation();
  const { showSnackbar } = useSnackbarContext();

  const togglePasswordVisibility = () => {
    setIsShow(!isShow);
  };

  const togglePasswordVisibility2 = () => {
    setIsShow2(!isShow2);
  };

  const username = location.state?.username;

  // 直接アクセスされた場合は変更受付画面へ遷移
  useEffect(() => {
    if (!username) {
      navigate("/reset-pw");
    }
  }, [navigate, username]);

  // パスコード再送信を１分間制限する
  useEffect(() => {
    if (isResending && timer > 0) {
      const interval = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
      return () => clearInterval(interval);
    } else if (timer <= 0) {
      setIsResending(false);
    }
  }, [isResending, timer]);

  // 確認コードを再送信する
  const resendCode = async () => {
    if (isResending) return;

    const result = await resetPassword({ username });

    if (result) {
      showSnackbar("success", appConstants.SEND_CODE);
      setIsResending(true);
      setTimer(60);
    }
  };

  const onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (newPassword !== confirmPassword) {
      setErrorMessage(appConstants.CONFIRM_PASSWORD_MISMATCH);
      setError(true);
      return;
    }

    try {
      await confirmResetPassword({
        username,
        newPassword,
        confirmationCode,
      });
      navigate("/login");
    } catch (resetError) {
      if (typeof resetError === "object" && resetError !== null) {
        if ("name" in resetError) {
          const resetErrorName = (resetError as { name: string }).name;
          switch (resetErrorName) {
            case "CodeMismatchException":
              setErrorMessage(appConstants.INVALID_CODE);
              break;
            case "ExpiredCodeException":
              setErrorMessage(appConstants.EXPIRED_CODE);
              break;
            case "InvalidPasswordException":
              setErrorMessage(appConstants.INVALID_PASSWORD);
              break;
            case "LimitExceededException":
              setErrorMessage(appConstants.EXPIRED_CODE);
              break;
            default:
              setErrorMessage(appConstants.OTHER_ERROR);
          }
          setError(true);
        }
      }
    }
  };

  return (
    <>
      <div className="commTitle icoMember">パスワードの再設定</div>
      <div className="loginWrap">
        <div className="formWrap">
          <ErrorMessage error={error} errorMessage={errorMessage} />
          <div className="notice">
            対象のメールアドレスに認証コードを送信しました。認証コードと新しいパスワードを入力してください。
          </div>
          <form onSubmit={onSubmit}>
            <div className="inputWrap">
              <input
                type="text"
                name="user"
                id="user"
                onChange={(e) => setConfirmationCode(e.target.value)}
                placeholder=""
                maxLength={6}
                required
              />
              <div className="placeholder">認証コード</div>
            </div>
            <div className="inputWrap">
              <span
                id="eye"
                className={isShow ? "fa fa-eye" : "fa fa-eye-slash"}
                onClick={togglePasswordVisibility}
              ></span>
              <input
                type={isShow ? "text" : "password"}
                name="pass"
                id="pass"
                onChange={(e) => setNewPassword(e.target.value)}
                placeholder=""
                maxLength={20}
                required
              />
              <div className="placeholder">新しいパスワード</div>
            </div>
            <div className="inputWrap">
              <span
                id="eye"
                className={isShow2 ? "fa fa-eye" : "fa fa-eye-slash"}
                onClick={togglePasswordVisibility2}
              ></span>
              <input
                type={isShow2 ? "text" : "password"}
                name="pass2"
                id="pass2"
                onChange={(e) => setConfirmPassword(e.target.value)}
                placeholder=""
                maxLength={20}
                required
              />
              <div className="placeholder">新しいパスワード（再入力）</div>
            </div>
            <div className="submitWrap">
              <button type="button" onClick={resendCode} disabled={isResending}>
                認証コードを再送信{isResending ? ` (${timer})` : ""}
              </button>
              <input type="submit" value="パスワードを更新" />
            </div>
          </form>
        </div>
      </div>
    </>
  );
}
