import { useEffect, useState } from "react";
import MicroModal from "micromodal";

interface CouponCodeModalSubmitProps {
  isUseCouponClick: boolean;
  setIsUseCouponClick: (value: boolean) => void;
}


const CouponCodeModalSubmit: React.FC<CouponCodeModalSubmitProps> = ({ isUseCouponClick, setIsUseCouponClick }) => {
  // const [isUseCouponClick, setIsUseCouponClick] = useState(false);

  // useEffect(() => {
  //   // モーダルが閉じられるイベントをキャッチするリスナーを追加
  //   const handleModalClose = () => {
  //     setIsUseCouponClick(false);
  //   };

  //   // micromodalの閉じるイベントを監視
  //   MicroModal.init({
  //     onClose: handleModalClose
  //   });

  //   // クリーンアップ
  //   return () => {
  //     const modal = document.getElementById('CouponCodeModal');
  //     if (modal) {
  //       modal.removeEventListener('onClose', handleModalClose);
  //     }
  //   };
  // }, []);
  
  return (
    <>
      <div className="useCoupon">
        <button onClick={() => {setIsUseCouponClick(true)}}>クーポンを使う</button> スタッフが確認いたしますので「ボタンを押さずに」この画面のままお待ちくださいませ。
      </div>
      {isUseCouponClick ? ( 
        <div className="useCouponSubmit">
          <button>使用済みにする</button>
        </div>
      ) : (
        null
      )}
    </>
  );
}
export default CouponCodeModalSubmit;