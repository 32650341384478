import HamburgerMenu from "../parts/menu/HamburgerMenu";
import "../../styles/reset-by-scss.css";
import "../../styles/mypage.css";
import { Link, Outlet, useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import { getCurrentUser } from "aws-amplify/auth";

export default function HomeLayout() {
  const [login, setLogin] = useState(false);
  const [username, setUsername] = useState("");

  const [currentClass, setCurrentClass] = useState({
    current1: "",
    current2: "",
    current3: "",
    current4: "",
  });
  const location = useLocation();

  useEffect(() => {
    const pathname = window.location.pathname;
    if (pathname.startsWith("/info")) {
      setCurrentClass({ current1: "", current2: "current", current3: "", current4: "" });
    } else if (pathname.startsWith("/coupon")) {
      setCurrentClass({ current1: "", current2: "", current3: "current", current4: "" });
    } else if (pathname.startsWith("/offer")) {
      setCurrentClass({ current1: "", current2: "", current3: "", current4: "current" });
    } else {
      setCurrentClass({ current1: "current", current2: "", current3: "", current4: "" });
    }
    checkAuthState();
  }, [location]);

  const checkAuthState = async () => {
    try {
      const user = await getCurrentUser();
      setUsername(user.username.slice(0, 4));
      setLogin(true);
    } catch {
      setLogin(false);
    }
  };

  return (
    <>
      <main>
        <HamburgerMenu />
        <div className="logoWrap">
          <Link to="/"><img src={"/images/logo.svg"} alt="logo"/></Link>
          <div className="login">
            {login ? (
              <Link to="/offer">
                <img src={"/images/icon/icoLogin2.png"} alt="loggedin-icon"/>
                こんにちは{username}さん
              </Link>
              ) : (
              <Link to="/login">
                <img src={"/images/icon/icoLogin1.png"} alt="login-icon"/>
                会員登録(ログイン)
              </Link>
            )}
          </div>
        </div>
        <div className="mypageWrap">
          <Outlet />
        </div>
      </main>
      <footer>
        <div className="menuWrap">
          <Link to="/" className={currentClass.current1}>
            <img src={"/images/icon/icoHome.svg"} alt="Home" />
            <span>ホーム</span>
          </Link>
          <Link
            to="coupon"
            className={currentClass.current3}
          >
            <img src={"/images/icon/icoCoupon.svg"} alt="Coupon" />
            <span>クーポン</span>
          </Link>
          <Link
            to="info"
            className={currentClass.current2}
          >
            <img src={"/images/icon/icoInfo.svg"} alt="Info" />
            <span>お知らせ</span>
          </Link>
          <Link to="offer" className={currentClass.current4}>
            <img src={"/images/icon/icoMypage.svg"} alt="Offers" />
            <span>スペシャル</span>
          </Link>
        </div>
      </footer>
    </>
  );
}
