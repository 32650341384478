import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import ErrorMessage from "../parts/ErrorMessage";
import { appConstants } from "../../../utils/appConstants";
import { useSnackbarContext } from "../../../utils/snackbar/SnackbarContext";
import { confirmSignUp, resendSignUpCode, signIn } from "aws-amplify/auth";

export default function SignupInputCode() {
  const navigate = useNavigate();
  const location = useLocation();
  const [confirmationCode, setConfirmationCode] = useState<string>("");
  const [isResending, setIsResending] = useState<boolean>(false);
  const [timer, setTimer] = useState<number>(0);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>(
    appConstants.INVALID_CODE
  );
  const { showSnackbar } = useSnackbarContext();

  const username = location.state?.username;
  const password = location.state?.password;

  // 直接アクセスされた場合はサインアップ画面へ遷移
  useEffect(() => {
    if (!location.state?.username || !location.state?.password) {
      navigate("/signup");
    }
  }, [location, navigate]);

  // 認証コード再送信を１分間制限する
  useEffect(() => {
    if (isResending && timer > 0) {
      const interval = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
      return () => clearInterval(interval);
    } else if (timer <= 0) {
      setIsResending(false);
    }
  }, [isResending, timer]);

  // 認証コードを再送信する
  const resendCode = async () => {
    if (isResending) return;

    const result = await resendSignUpCode({ username });

    if (result) {
      showSnackbar("success", appConstants.SEND_CODE);
      setIsResending(true);
      setTimer(60);
    }
  };

  const onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    try {
      const { isSignUpComplete } = await confirmSignUp({
        username,
        confirmationCode,
      });
      // console.log(nextStep);
      if (isSignUpComplete) {
        completeSignUp();
      } else {
        setErrorMessage(appConstants.OTHER_ERROR);
        setError(true);
        return;
      }
    } catch (signUpError) {
      if (typeof signUpError === "object" && signUpError !== null) {
        if ("name" in signUpError) {
          const signUpErrorName = (signUpError as { name: string }).name;
          switch (signUpErrorName) {
            case "CodeMismatchException":
              setErrorMessage(appConstants.INVALID_CODE);
              break;
            case "ExpiredCodeException":
              setErrorMessage(appConstants.EXPIRED_CODE);
              break;
            case "LimitExceededException":
              setErrorMessage(appConstants.EXPIRED_CODE);
              break;
            default:
              setErrorMessage(appConstants.OTHER_ERROR);
          }
          setError(true);
        }
      }
    }
  };

  const completeSignUp = async () => {
    try {
      const { nextStep } = await signIn({
        username,
        password
      });
      if (nextStep.signInStep === "DONE") {
        // TODO ログイン成功時にDBに登録情報を保存する処理を追加
        showSnackbar("success", appConstants.SIGNUP_SUCCESS);
        navigate("/");
      } else {
        console.log("login error nextstep required:", nextStep);
      }
    } catch (loginError) {
      console.log("loginError", loginError);
    }
  };

  return (
    <>
      <div className="commTitle icoMember">メールアドレスの確認</div>
      <div className="loginWrap">
        <div className="formWrap">
          <ErrorMessage error={error} errorMessage={errorMessage} />
          <div className="notice">
            メールに記載されている認証コードを入力してログインしてください。到着まで1分ほどかかる場合があります。
          </div>
          <form onSubmit={onSubmit}>
            <div className="inputWrap">
              <input
                type="text"
                name="user"
                id="user"
                onChange={(event) => setConfirmationCode(event.target.value)}
                placeholder=""
                maxLength={6}
                required
              />
              <div className="placeholder">認証コード</div>
            </div>
            <div className="submitWrap">
              <button type="button" onClick={resendCode} disabled={isResending}>
                認証コードを再送信する{isResending ? ` (${timer})` : ""}
              </button>
              <input type="submit" value="会員登録する" />
            </div>
          </form>
        </div>
      </div>
    </>
  );
}
