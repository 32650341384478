import { getCurrentUser, signOut } from "aws-amplify/auth";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { appConstants } from "../../utils/appConstants";
import { useSnackbarContext } from "../../utils/snackbar/SnackbarContext";


export default function Offer() {
  const [login, setLogin] = useState(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [email, setEmail] = useState("okage-crm@example.com");
  const [phoneNumber, setPhoneNumber] = useState("05099999999");
  const [postalCode, setPostalCode] = useState("1501111");
  const [address1, setAddress1] = useState("東京都渋谷区渋谷");
  const [address2, setAddress2] = useState("123-45 サンプルビル 101号室");
  const { showSnackbar } = useSnackbarContext();
  
  useEffect(() => {
    checkAuthState();
  }, []);

  const checkAuthState = async () => {
    try {
      await getCurrentUser();
      setLogin(true);
    } catch {
      setLogin(false);
    }
    finally {
      setIsLoading(false);
    }
  };

  const logout = async () => {
    try {
      await signOut();
      window.location.reload();
    } catch {
      showSnackbar("error", appConstants.SESSION_DISCONNECTION);
    }
  };

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    showSnackbar("success", appConstants.SUCCESS_UPDATE);
  }

  if (isLoading) {
    return <div>Loading...</div>;  // Or any loading spinner
  }

  return (
    <>
      <div className="mypageWrap">
        <div className="offerWrap commSpacer single">
          {login ? (
            <>
              <div className="commTitle icoMember">クーポン利用履歴</div>
              <div className="memberInfoWrap">
                <p>5/12 青物横丁店 チキンバーガーセット</p>
                <p>5/3  蕨店      BIGバーガーセット</p>
                <p>4/27 天王寺店 サラダ(S)</p>
              </div>
              <div className="commTitle icoMember">会員情報の変更</div>
              <div className="memberInfoWrap">
                <div className="formWrap">
                  <form onSubmit={handleSubmit}>
                    <div className="inputWrap">
                      <input 
                        type="text"
                        name="email"
                        id="email"
                        value={email}
                        placeholder=""
                        maxLength={30}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                      <div className="placeholder">
                        メールアドレス
                      </div>
                    </div>
                    <div className="inputWrap">
                      <input 
                        type="text"
                        name="phoneNumber"
                        id="phoneNumber"
                        value={phoneNumber}
                        placeholder=""
                        maxLength={12}
                        onChange={(e) => setPhoneNumber(e.target.value)}
                      />
                      <div className="placeholder">
                        電話番号（ハイフンなし）
                      </div>
                    </div>
                    <div className="inputWrap">
                      <input 
                        type="text"
                        name="postalCode"
                        id="postalCode"
                        value={postalCode}
                        placeholder=""
                        maxLength={7}
                        onChange={(e) => setPostalCode(e.target.value)}
                      />
                      <div className="placeholder">
                        郵便番号（ハイフンなし）
                      </div>
                    </div>
                    <div className="inputWrap">
                      <input 
                        type="text"
                        name="address1"
                        id="address1"
                        value={address1}
                        placeholder=""
                        maxLength={30}
                        onChange={(e) => setAddress1(e.target.value)}
                      />
                      <div className="placeholder">
                        住所1（都道府県・市区町村）
                      </div>
                    </div>
                    <div className="inputWrap">
                      <input 
                        type="text"
                        name="address2"
                        id="address2"
                        value={address2}
                        placeholder=""
                        maxLength={50}
                        onChange={(e) => setAddress2(e.target.value)}
                      />
                      <div className="placeholder">
                        住所2（番地・建物名・部屋番号など）
                      </div>
                    </div>
                    <div className="submitWrap">
                      <input type="submit" value="会員情報を更新する" />
                    </div>
                  </form>               
                </div>
              </div>
              <div className="memberInfoWrap">
                <div className="formWrap">
                  <div className="submitWrap">
                    <button onClick={logout}>ログアウト</button>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <>
              <div className="commTitle icoMember">スペシャルオファーのご案内</div>
              <div className="commTextbody">
                OkageCRMの会員になれば、もっとおトクに♪もっと楽しく♪
                <br /><br />
                毎日チャレンジ！<br />特別なクーポンが当たるゲームもあるよ♪
                <div className="offer">
                  <Link to="/login">
                    ログイン / 会員登録
                    <span>60秒！簡単登録！</span>
                  </Link>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
}

