/**
 * ログイン済みの場合は設定画面にリダイレクトするPublicRouteコンポーネント
 */

import { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import { getCurrentUser } from 'aws-amplify/auth';

interface PublicRouteProps {
  children: JSX.Element;
}

const PublicRoute = ({ children }: PublicRouteProps) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    checkAuthState();
  }, []);

  const checkAuthState = async () => {
    try {
      await getCurrentUser();
      setIsAuthenticated(true);
    } catch {
      setIsAuthenticated(false);
    }
    finally {
      setIsLoading(false);
    }
  };

  if (isLoading) {
    return <div>Loading...</div>;  // Or any loading spinner
  }

  return isAuthenticated ? <Navigate to="/" /> : children;
};

export default PublicRoute;
