import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

export interface Info {
  id: number;
  title: string;
  body: string;
  link: string;
  created_at: string;
  updated_at: string;
  status: number;
  delflg: number;
}

interface InfoState {
  infos: Info[];
  loading: boolean;
  error: string | null;
}

const initialState: InfoState = {
  infos: [],
  loading: false,
  error: null
};

export const fetchInfos = createAsyncThunk('infos/fetchInfos', async () => {
  const response = await axios.get<Info[]>(`${process.env.REACT_APP_API_BASE_URL}/api/info`);
  return response.data;
});

const infoSlice = createSlice({
  name: 'infos',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchInfos.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchInfos.fulfilled, (state, action) => {
        state.loading = false;
        state.infos = action.payload;
      })
      .addCase(fetchInfos.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || 'Something went wrong';
      });
  }
});

export default infoSlice.reducer;
