import React, { useEffect, useState } from 'react';
import MicroModal from 'micromodal';
import '../../../styles/micromodal.css';
import CouponCodeModalSubmit from './CouponCodeModalSubmit';


interface CouponCodeModalProps {
  isOpen: boolean;
  onClose: () => void;
  isSpecial: string;
  code: string;
  login: boolean;
}

const CouponCodeModal: React.FC<CouponCodeModalProps> = ({ isOpen, onClose, isSpecial, code, login }) => {
  const [isUseCouponClick, setIsUseCouponClick] = useState(false);

  const handleModalClose = () => {
    setIsUseCouponClick(false);
    onClose();
  };

  useEffect(() => {
    MicroModal.init({
      disableScroll: true,
      awaitOpenAnimation: true,
      awaitCloseAnimation: true,
    });

    if (isOpen) {
      MicroModal.show('CouponCodeModal', {
        onShow: modal => console.log('QR Modal is shown'),
        onClose: handleModalClose
      });
    }
  }, [isOpen, onClose]);

  return (
    <div id="CouponCodeModal" aria-hidden="true" className="modal micromodal-slide">
      <div className="modal__overlay" tabIndex={-1} data-micromodal-close="">
        <div className="modal__container single" role="dialog" aria-modal="true" aria-labelledby="modal-title">
          <div className="couponcodeWrap">
            <div className="msg1">
              クーポン
            </div>
            <div className="code">
              {code}
            </div>
            <div className="msg2">
              店舗スタッフにご提示ください
            </div>
            {login && isSpecial === "1" ? (
              <CouponCodeModalSubmit 
                isUseCouponClick={isUseCouponClick}
                setIsUseCouponClick={setIsUseCouponClick}
              />
              ) : null
            }
          </div>
          <button className="modal__close" aria-label="Close modal" data-micromodal-close=""></button>
        </div>
      </div>
    </div>
  );
};
export default CouponCodeModal;
